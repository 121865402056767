import React, { lazy } from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HelmetMeta } from "./HelmetMeta";
import { ThemeProvider } from "../components/theme/ThemeProvider";
import { CssBaseline } from "@material-ui/core";
import { logCredits } from "../utils/logCredits";

import { Home } from "../pages/Home";

//const Resume = lazy(() => import("../pages/Resume"));
const PageNotFound = lazy(() => import("../pages/PageNotFound"));

export const App = () => {
    logCredits();

    return (
        <>
        <ThemeProvider>
            <CssBaseline />
            <Router>
                <HelmetMeta />
                <Routes>
                    <Route path="/" exact element={<Home />} />
                    <Route path="*" component={PageNotFound} />
                </Routes>
            </Router>
        </ThemeProvider>
        </>
    );
};
