import React from 'react';
import { LogoLink } from '../components/logo/LogoLink';
import { Content } from '../components/content/Content';
import { makeStyles } from '@material-ui/core/styles';
import { SocialIcons } from '../components/content/SocialIcons';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 80px);',
    maxHeight: '100%'
  },
}));

export const Home = () => {
  const classes = useStyles();
   
  return (
    <>
      <div className={classes.root}>
        <LogoLink />
        <Content />
          <SocialIcons />
      </div>
    </>
  );
};
