import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    svgHover: {
        color: theme.palette.foreground.default,
        "&:hover": {
            color: theme.palette.primary.main,
        },
        transition: "all 0.5s ease",
    },
}));

export const Logo = () => {
    const classes = useStyles();

    return (
        <div className={classes.svgHover}> .DL; </div>
        
    );
};
